<template>
    <div class="items">
        <div class="row_left">
            <el-image
                style="width: 100%;"
                :src="imageUrl + 'all_product/' + itemData.image_path + '/main-1.png'"
                fit="contain">
            </el-image>
        </div>
        <div class="row_right">
            <div class="title">
                <el-space :size="0" wrap>
                    <span v-for="(item,index) in typeData" :key="index" :class="item.flag ? 'hightLight' : ''">
                        {{item.text}}
                    </span>
                </el-space>
            </div>
            <div class="text" >
                <el-space :size="0" wrap>
                    <span style="margin-right: 8px;">{{itemData.mode?.title}}:</span>
                    <span v-for="(item,index) in modeData" :key="index" :class="item.flag ? 'hightLight' : ''">
                        {{item.text}}
                    </span>
                </el-space>
            </div>
            <div class="text" >
                <el-space :size="0" wrap>
                    <span style="margin-right: 8px;">{{itemData.material?.title}}: </span>
                    <span v-for="(item,index) in materialData" :key="index" :class="item.flag ? 'hightLight' : ''">
                        {{item.text}}
                    </span>
                </el-space>
            </div>
            <div class="text" >
                <el-space :size="0" wrap>
                    <span style="margin-right: 8px;">{{itemData.body_color?.title}}: </span>
                    <span v-for="(item,index) in colorData" :key="index" :class="item.flag ? 'hightLight' : ''">
                        {{item.text}}
                    </span>
                </el-space>
            </div>
            <div class="text" >
                <el-space :size="0" wrap>
                    <span v-for="(item,index) in keywordsData" :key="index" :class="item.flag ? 'hightLight' : ''">
                        {{item.text}}
                    </span>
                </el-space>
            </div>
        </div>

    </div>
</template>
<script>
import {ImageOSSUrl} from "@/js/netApi";

export default {
    name: "SearchResultItem",
    data() {
        return {
            itemData: {},
            typeData: [],
            modeData: [],
            materialData: [],
            colorData: [],
            keywordsData: [],
            searchKeyword: '',
            imageUrl: ImageOSSUrl
        };
    },
    props: {
        productItem: {},
        keyword: {
            type: String,
            default: ''
        }
    },
    watch: {
        'productItem': function () {
            this.itemData = this.productItem
            this.checkAllKeyword()
        },
        'keyword': function () {
            this.searchKeyword = this.keyword
        }
    },
    mounted() {
        this.itemData = this.productItem
        this.searchKeyword = this.keyword
        this.checkAllKeyword()
    },
    methods: {
        checkAllKeyword() {
            if ((this.itemData) && (this.searchKeyword)) {
                this.typeData = this.checkKeyword(this.searchKeyword, this.itemData.type.value);
                this.modeData = this.checkKeyword(this.searchKeyword, this.itemData.mode.value);
                this.materialData = this.checkKeyword(this.searchKeyword, this.itemData.material.value);
                this.colorData = this.checkKeyword(this.searchKeyword, this.itemData.body_color.value);
                this.keywordsData = this.checkKeyword(this.searchKeyword, this.itemData.keywords.value.join('、'));
            }
        },
        checkKeyword(keyword, text) {
            let result = {};
            let currentIndex = 0;
            let returnData = [];

            if ((keyword === '') || (text === '')) {
                returnData.push({
                    flag: false,
                    text: text
                })

                return returnData
            }
            const reg = new RegExp(keyword, "gi");

            while ((result = reg.exec(text))) {
                let str = text.slice(currentIndex, result.index)
                if (str.length > 0) {
                    returnData.push({
                        flag: false,
                        text: str
                    })
                }

                returnData.push({
                    flag: true,
                    text: result[0]
                })

                currentIndex = result.index + result[0].length;
            }

            if (text.length > currentIndex) {
                returnData.push({
                    flag: false,
                    text: text.slice(currentIndex, text.length)
                })
            }

            return returnData;
        }
    }
};
</script>

<style scoped>
    .hightLight {
        color: red;
    }

    .items {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 40px;
        padding: 10px 10px;

    }

    .items:hover {
        cursor: pointer;
        box-shadow: 3px 5px 8px 5px rgba(0, 0, 0, 0.1);
    }

    .row_left {
        width: 30%;
        overflow: hidden;
        height: 100%;
    }

    .row_right {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 65%;
        height: 100%;
    }

    .title {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        color: #333333;
        font-size: 18px;
        width: 100%;
        margin-bottom: 16px;
    }

    .text {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        color: #888888;
        font-size: 14px;
        width: 100%;
        margin: 10px 0;
    }

    .more {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        color: #888888;
        font-size: 14px;
        width: 100%;
    }

    .el-image {
        transition: scale 1s;
        -webkit-transition: scale 1s;
    }

    .el-image:hover {
        scale: 1.1;

    }
</style>

