<template>
    <div class="caseContainer">
        <div class="top_image_banner" :style="'background-image: url(' + imageUrl + projectData.banner + ');'">
            <span class="banner_title">{{projectData.title}}</span>
        </div>
        <div class="case_swiper" v-for="(projectItem, index) in projectData.items" :key="index">
            <div class="case_swiper_image_view" v-for="(item,idx) in projectItem.items" :key="idx">
                <el-image
                    :src="imageUrl + item.image"
                    fit="cover">
                </el-image>
            </div>

        </div>
    </div>
</template>

<script>
import {ImageOSSUrl} from "@/js/netApi";
import {EffectFade, Navigation, Pagination} from "swiper";

export default {
    name: "ProjectComponent",
    components: {


    },
    setup() {
        return {
            modules: [EffectFade, Navigation, Pagination],
        };
    },
    data() {
        return {
            windowHeight: 0,
            imageUrl: ImageOSSUrl,
            projectData: {},
            projectSwiper: null
        }
    },
    beforeRouteEnter() {
        window.scrollTo(0,0)
    },
    mounted() {
        this.projectData = this.webData
    },
    methods: {
        onSwiper(swiper_type) {
            let swiper = this.$refs[swiper_type][0].$el.swiper
            if (swiper_type === 'project') {
                this.projectSwiper = swiper
            }
        },
        onSwiperNavClicked(swiper_type,direction) {
            let swiperObj = null;
            if (swiper_type === 'project') {
                swiperObj = this.projectSwiper
            } else {
                return
            }

            if (!swiperObj) {
                return
            }

            if (direction === 'left') {
                swiperObj.slidePrev()
            } else {
                swiperObj.slideNext()
            }
        }
    },
    props: {
        'webData': {}
    }
}
</script>

<style scoped>
    .caseContainer {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        background-color: #333333;
    }

    .top_image_banner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100vh;
        background-repeat: no-repeat;
        background-position: center center;
        margin-bottom: 3vw;
    }

    .banner_title {
        color: #FFF;
        font-size: 72px;
    }

    .case_swiper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        margin-top: 1vw;
    }

    .case_swiper .case_swiper_nav_view:hover {
        cursor: pointer;
    }

    .case_swiper_image_view {
        width: 100%;
        margin-top: 10px;
    }



    .case_swiper_image_title {
        position: absolute;
        left: 50px;
        top: 50px;
        color: #FFF;
        font-size: 64px;
        font-weight: bold;
        z-index: 10;
    }

    .case_swiper_nav_view {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 64px;
        height: 64px;
        border-radius: 50% 50%;
        background-color: #FFF;
        z-index: 10;
        top: 50%;
        opacity: 0;
        transition-duration: 0.8s;
    }

    .slideItem {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .case_content_view {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 25%;
        height: 80%;
    }

    .case_content_title  {
        font-size: 18px;
        color: #333333;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .case_content_text  {
        font-size: 14px;
        color: #888888;
        width: 100%;
        white-space: pre-wrap;
    }

    .slideThumbItem {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        width: 100%;
        height:100%;
        padding: 10px 0;
        margin: 10px;
        box-sizing: border-box;
        opacity: 0.3;
    }

    .slideThumbItemSelected {
        opacity: 1;
    }

    .thumbItemText {
        width: 100%;
        text-align: left;
        background-color: #33333360;
        color: #eee;
        font-size: 12px;
        padding-left: 10px;
    }
</style>