<template>
    <div class="about_container" v-if="'AboutTab' in aboutData">
        <div class="top_image_banner" :style="'background-image: url(' + imageUrl + aboutData.banner + ');'">
            <span class="banner_title">{{aboutData.title}}</span>
            <span class="banner_desc">{{aboutData.subtitle}}</span>
        </div>
        <div class="about_tab">
            <div v-for="(item, index) in aboutData.AboutTab" :key="index"
                 :class="'about_tab_item ' + (item.idx === activeIndex ? 'about_tab_item_selected' : '') "
                 @click="activeIndex = item.idx">
                <el-icon>
                    <component :is="item.icon"></component>
                </el-icon>
                <span >{{item.name}}</span>
            </div>
        </div>
        <div class="about_view wow animate__animated animate__fadeInUp" v-if="activeIndex === aboutData.AboutTab[0].idx">
            <div class="team_view">
                <el-image
                    style="width: 50%"
                    :src="imageUrl + aboutData.EnterpriseLabel.imageLeft"
                    fit="contain">
                </el-image>
                <span style="width: 45%;color:#333;line-height: 2em;white-space: pre-wrap;">{{aboutData.EnterpriseLabel.descRight}}</span>
            </div>
            <div class="team_view" style="margin-top: 40px">
                <span style="width: 45%;color:#333;line-height: 2em;white-space: pre-wrap; ">{{aboutData.EnterpriseLabel.descLeft}}</span>
                <el-image
                    style="width: 50%"
                    :src="imageUrl + aboutData.EnterpriseLabel.imageRight"
                    fit="contain">
                </el-image>

            </div>
        </div>
        <div class="about_view wow animate__animated animate__fadeInUp" v-if="activeIndex === aboutData.AboutTab[1].idx">
            <div class="team_view">
                <div class="team_view_item" v-for="(item, index) in aboutData.TeamItems" :key="index">
                    <div class="team_view_item_top" :style="'background-image: url(' + imageUrl + item.image + ');'">
                        <span class="team_view_item_top_title">{{item.title}}</span>
                    </div>
                    <div class="team_view_item_bottom">
                        <span v-for="(txt, idx) in item.content" :key="idx">{{txt}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="about_view wow animate__animated animate__fadeInUp" v-if="activeIndex === aboutData.AboutTab[2].idx">
            <span class="hr_tips" v-for="(tips, idd) in aboutData.HrLabel" :key="idd">{{tips}}</span>
            <el-collapse class="collapse" v-model="HrActiveName" accordion>
                <el-collapse-item v-for="(item,index) in aboutData.HrData" :key="index"
                                  :name="index" class="collapse_item">
                    <template #title>
                        <div class="collapse_title_view">
                            <span class="title">{{item.title}}</span>
                            <div>
                            <span v-for="(tag,idx) in item.tag" :key="idx" class="tag" >
                                {{tag}}
                            </span>
                            </div>
                        </div>
                    </template>
                    <div v-for="(itm,id) in item.desc" :key='id'>
                        <span>{{itm}}</span>
                    </div>
                </el-collapse-item>
            </el-collapse>
            <span class="tips">
                {{aboutData.HrLabel.tips}}
            </span>
        </div>
    </div>
</template>

<script>
import {Swiper, SwiperSlide} from "swiper/vue";
import {ImageOSSUrl} from "@/js/netApi";

export default {
    name: "AboutComponent",
    components: {
        Swiper,
        SwiperSlide
    },
    watch: {
        'defaultIndex': function () {
            this.activeIndex = this.defaultIndex
        }
    },
    props: {
        'defaultIndex': {
            type: String,
            default: ''
        },
        'webData': {}
    },
    data() {
        return {
            activeIndex: '5-3',
            HrActiveName: '',
            QuestionActiveName: '',
            imageUrl: ImageOSSUrl,

            mapEditForm:{
                address: '',
                lng:0,
                lat:0
            },
            aboutData: {}
        }
    },
    beforeRouteEnter() {
        window.scrollTo(0,0)
    },
    mounted() {
        this.activeIndex = this.defaultIndex
        this.HrActiveName = 0
        this.aboutData = this.webData
    },
    methods: {
        onBannerClicked(idx,url,type) {
            console.log(idx)
            console.log(url)
            console.log(type)
        },
    }
}
</script>

<style scoped>
    .about_container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
    }

    .top_image_banner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100vh;
        background-repeat: no-repeat;
        background-position: center center;
    }

    .banner_title {
        color: #FFF;
        font-size: 72px;
    }

    .banner_desc {
        color: #FFF;
        font-size: 48px;
    }

    .about_view {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 60%;
        margin-bottom: 80px;
    }

    .team_view {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
    }

    .team_view_item {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 14vw;
    }

    .team_view_item_top {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 14vw;
        height: 14vw;
        background-size:cover;
        background-repeat: no-repeat;
        background-position: center center;
    }

    .team_view_item_top_title {
        font-size: 28px;
        color: #FFF;
        background-color: #33333350;
        text-align: left;
        width: 100%;
        margin-top: 10px;
    }

    .team_view_item_bottom {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        font-size: 16px;
        color: #333333;
        margin-top: 8px;
    }

    .team_view_item_bottom > span {
        margin-top: 4px;
        margin-bottom: 4px;
    }

    .marketing_bottom {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 45%;
    }

    .slideItem {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        background-size:cover;
        background-repeat: no-repeat;
        color: #FFF;
        font-size: 18px;
        width: 100%;
        height: 100%;
    }

    .about_tab {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 60%;
        margin-top: 80px;
        margin-bottom: 80px;
    }

    .about_tab_item {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        width: 12%;
        color: #888888;
        font-size: 18px;
        padding: 10px 20px 10px 20px;
        cursor: pointer;
    }

    .about_tab_item:hover {
        background-color: #CFA88C30;
        border-radius: 100px;
        color: #CFA88C;
    }

    .about_tab_item_selected {
        background-color: #CFA88C30;
        border-radius: 100px;
        color: #CFA88C;
    }

    .hr_tips {
        width: 65%;
        color: #333;
        font-size: 16px;
        margin: 10px;
    }

    .collapse {
        border-top: none;
        border-bottom: none;
        width: 70%;
        margin-top: 40px;
    }

    .collapse_item {
        display: block;
        box-shadow: 0 3px 10px 0 #0000001F;
        margin-bottom: 20px;
        padding: 20px;
        color: #333;
        font-size: 18px;
        min-width: 100%;
        margin-right: 20px;
        border-radius: 10px;
        white-space: pre-wrap;
    }

    .collapse_title_view {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
    }

    .tag {
        padding: 3px 10px;
        border: solid 1px #CFA88C;
        font-size: 12px;
        color: #CFA88C;
        margin-right: 10px;
    }

    .title {
        font-size: 18px;
        color: #333;
        margin-bottom: 10px;
    }

    .tips {
        width: 70%;
        margin-top: 40px;
        margin-bottom: 40px;
        color: #888;
        font-size: 12px;
        text-align: center;
    }

    :deep(.el-collapse-item__header) {
        border-bottom: none !important;
        font-size: 16px;
        color: #333;
        line-height: normal;
    }

    :deep(.el-collapse-item__content) {
        margin-top: 20px;
        color: #888888;
        font-size: 14px;
    }


</style>