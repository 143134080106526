<template>
    <div class="designFlowContainer" v-if="designFlowData.blocks">
        <div class="top_image_banner" :style="'background-image: url(' + imageUrl + designFlowData.banner + ');'">
            <span class="banner_title">{{designFlowData.title}}</span>
        </div>
        <div class="designItemOnePicContent" style="align-items: flex-start;">
            <div class="designItemOnePic"
                 :style="'background-image: url(' + imageUrl + designFlowData.blocks[0].image + ');background-size:cover;background-repeat:no-repeat;background-position:center center;'">

            </div>
            <div class="designItemOneContent">
                <div class="designItemGroup" v-for="(item, index) in designFlowData.blocks[0].contents" :key="index">
                    <span class="itemTitle">{{item.title}}</span>
                    <span class="itemContent">{{item.content}}</span>
                    <span class="itemTitle" v-if="item.gap"></span>
                </div>
            </div>
        </div>
        <div class="designItemOnePicContent" >
            <div class="designItemOneContent">
                <div class="designItemGroup" v-for="(item, index) in designFlowData.blocks[1].contents" :key="index">
                    <span class="itemTitle">{{item.title}}</span>
                    <span class="itemContent">{{item.content}}</span>
                    <span class="itemTitle" v-if="item.gap"></span>
                </div>
            </div>
            <div class="designFlowFourPic">
                <div class="designFlowTwoPic" v-for="(items,index) in designFlowData.blocks[1].items" :key="index">
                    <el-image v-for="(item, idx) in items" :key="idx"
                        style="width: 18vw;height:18vw;margin:10px;"
                        :src="imageUrl + item"
                        fit="cover">
                    </el-image>
                </div>
            </div>
        </div>
        <div class="designItemOnePicContent" style="align-items: flex-start;">
            <div class="designItemOnePic"
                 :style="'background-image: url(' + imageUrl + designFlowData.blocks[2].image + ');background-size:contain;background-repeat:no-repeat;'">

            </div>
            <div class="designItemOneContent">
                <div class="designItemGroup" v-for="(item, index) in designFlowData.blocks[2].contents" :key="index">
                    <span class="itemTitle">{{item.title}}</span>
                    <span class="itemContent">{{item.content}}</span>
                    <span class="itemTitle" v-if="item.gap"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {ImageOSSUrl} from "@/js/netApi";
export default {
    name: "DesignFlowComponent",
    props: {
        'webData': {}
    },
    data() {
        return {
            imageUrl: ImageOSSUrl,
            designFlowData: {}
        }
    },
    beforeRouteEnter() {
        window.scrollTo(0,0)
    },
    mounted() {
        this.designFlowData = this.webData
    },
    methods: {

    }
}
</script>

<style scoped>
    .designFlowContainer {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
    }

    .designFlowFourPic {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 50%;
    }

    .designFlowTwoPic {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .designItemGroup {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
    }
</style>