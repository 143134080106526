<template>
    <div class="designContainer"  style="overflow:hidden" v-if="designData.blocks">
        <div class="top_image_banner" :style="'background-image: url(' + imageUrl + designData.banner + ');'">
            <span class="banner_title">{{designData.title}}</span>
        </div>
        <div :class="'designItemOnePicContent ' + designData.blocks[0].dark">
            <div class="designItemOneSmallPic"
                 :style="'background-image: url(' + imageUrl + designData.blocks[0].image + ');background-size:cover;background-repeat:no-repeat;'">

            </div>
            <div class="designItemOneContent">
                <span class="itemTitle">{{designData.blocks[0].title}}</span>
                <span class="itemContent">{{designData.blocks[0].content}}</span>
            </div>

        </div>
        <div :class="'designItemOnePicContent ' + designData.blocks[1].dark">

            <div class="designItemOneContent">
                <span class="itemTitle">{{designData.blocks[1].title}}</span>
                <span class="itemContent">{{designData.blocks[1].content}}</span>
            </div>

            <div class="designItemOnePic"
                 :style="'background-image: url(' + imageUrl + designData.blocks[1].image + ');background-size:cover;background-repeat:no-repeat;'">

            </div>

        </div>
        <div :class="'designItemLongPicContent ' + designData.blocks[2].dark" >
            <div class="designItemLongContent">
                <span class="itemTitle">{{designData.blocks[2].title}}</span>
                <div class="designItemLongPic"
                     :style="'background-image: url(' + imageUrl + designData.blocks[2].image + ');background-size:contain;background-repeat:no-repeat;'">

                </div>
                <span class="itemContent">{{designData.blocks[2].content}}</span>
            </div>
        </div>
        <div :class="'designItemLongPicContent ' + designData.blocks[3].dark" >
            <div class="designItemLongContent">
                <span class="itemTitle">{{designData.blocks[3].title}}</span>
                <span class="itemContent">{{designData.blocks[3].content}}</span>
                <table class="designTable">
                    <tr>
                        <th class="designTableColumn" v-for="(item,index) in designData.blocks[3].th" :key="index">{{item}}</th>
                    </tr>
                    <tr v-for="(items,index) in designData.blocks[3].td" :key="index">
                        <td class="designTableColumn" v-for="(item,idx) in items" :key="idx">{{item}}</td>
                    </tr>
                </table>
            </div>
        </div>
        <div :class="'designItemLongPicContent ' + designData.blocks[4].dark" >
            <div class="designItemLongContent">
                <span class="itemTitle">{{designData.blocks[4].title}}</span>
                <span class="itemContent" v-for="(item, index) in designData.blocks[4].contents" :key="index">{{item}}</span>
                <div class="designItemOnePicContent" style="padding: 0;margin-top: 20px">

                    <div class="designItemOneContent">
                        <span class="itemContent">
                            {{designData.blocks[4].desc}}
                        </span>
                    </div>

                    <div class="designItemOnePic"
                         :style="'background-image: url(' + imageUrl + designData.blocks[4].image + ');background-size:cover;background-repeat:no-repeat;'">

                    </div>

                </div>

            </div>
        </div>
        <div :class="'designItemOnePicContent ' + designData.blocks[5].dark">
            <div class="designItemOnePic"
                 :style="'background-image: url(' + imageUrl + designData.blocks[5].image + ');background-size:cover;background-repeat:no-repeat;'">

            </div>

            <div class="designItemOneContent">
                <span class="itemTitle">{{designData.blocks[5].title}}</span>
                <span class="itemContent">{{designData.blocks[5].content}}</span>
            </div>

        </div>
        <div :class="'designItemLongPicContent ' + designData.blocks[6].dark" >
            <div class="designItemLongContent">
                <span class="itemTitle">{{designData.blocks[6].title}}</span>
                <span class="itemContent" style="margin-bottom: 20px">
                    {{designData.blocks[6].contents[0]}}
                </span>

                <div class="designItemLongPic"
                     :style="'background-image: url(' + imageUrl + designData.blocks[6].images[0] + ');background-size:contain;background-repeat:no-repeat;height:17vw'">

                </div>
                <div class="designItemLongPic"
                     :style="'background-image: url(' + imageUrl + designData.blocks[6].images[1] + ');background-size:contain;background-repeat:no-repeat;height:12vw'">

                </div>
                <div class="designItemOnePicContent" style="padding: 0">

                    <div class="designItemOneContent">
                        <span class="itemContent">{{designData.blocks[6].contents[1]}}</span>
                    </div>

                    <div class="designItemOnePic"
                         :style="'background-image: url(' + imageUrl + designData.blocks[6].images[2] + ');background-size:cover;background-repeat:no-repeat;'">

                    </div>

                </div>
                <div class="designItemOnePicContent" style="padding: 0">
                    <div class="designFourItem" v-for="(item, index) in designData.blocks[6].items" :key="index">
                        <div class="designItemFourPic"
                             :style="'background-image: url(' + imageUrl + item.image + ');background-size:cover;background-repeat:no-repeat;'">

                        </div>
                        <span>{{item.title}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div :class="'designItemLongPicContent ' + designData.blocks[7].dark" >
            <span class="itemTitle">{{designData.blocks[7].title}}</span>
            <span class="itemContent">
                {{designData.blocks[7].contents[0]}}
            </span>
            <span class="itemContent" style="margin-bottom: 20px">
                {{designData.blocks[7].contents[1]}}
            </span>
            <div class="designItemLongPic"
                 :style="'background-image: url(' + imageUrl + designData.blocks[7].images[0] + ');background-size:contain;background-repeat:no-repeat;height:17vw'">

            </div>
            <div class="designItemLongPic"
                 :style="'background-image: url(' + imageUrl + designData.blocks[7].images[1] + ');background-size:contain;background-repeat:no-repeat;height:17vw'">
            </div>
            <span>
                {{designData.blocks[7].contents[2]}}
            </span>
            <table class="designTable">
                <tr>
                    <th class="designTableColumn" v-for="(item,index) in designData.blocks[7].th" :key="index">{{item}}</th>
                </tr>
                <tr v-for="(items,index) in designData.blocks[7].td" :key="index">
                    <td class="designTableColumn" v-for="(item,idx) in items" :key="idx">{{item}}</td>
                </tr>
            </table>
        </div>
        <div style="height: 20px"></div>
    </div>

</template>

<script>
import {ImageOSSUrl} from "@/js/netApi";
export default {
    name: "DesignComponent",
    props: {
        'webData': {}
    },
    data() {
        return {
            imageUrl: ImageOSSUrl,
            designData: {}
        }
    },
    beforeRouteEnter() {
        window.scrollTo(0,0)
    },
    mounted() {
        this.designData = this.webData
    },
    methods: {

    }
}
</script>

<style>
    .designContainer {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
    }

    .designItemOnePicContent {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 5vw 10vw;
        box-sizing: border-box;
        color: #333;
    }

    .top_image_banner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100vh;
        background-repeat: no-repeat;
        background-position: center center;
        margin-bottom: 3vw;
    }

    .banner_title {
        color: #FFF;
        font-size: 72px;
    }

    .designItemLongPicContent {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding: 5vw 10vw;
        box-sizing: border-box;
        color: #333;
    }

    .designItemLongContent {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 80vw;
    }

    .designItemLongPic {
        width: 80vw;
        height: 30vw;
        margin-bottom: 40px;
    }

    .designContentDark {
        background-color: #333 ;
        color: #FFF ;
    }

    .designItemOneSmallPic {
        width: 25vw;
        height: 25vw;

    }

    .designItemOnePic {
        width: 30vw;
        height: 30vw;

    }

    .designItemOneContent {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 50%;
    }

    .designFourItem {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 18vw;
        height: 14vw;
    }

    .designItemFourPic {
        width: 100%;
        height: 90%;
    }



    .itemTitle {
        font-size: 36px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .itemContent {
        font-size: 24px;
        line-height: 48px;
    }

    .designTable {
        border-spacing: 0;
        border: solid 1px #FFF;
        width: 80vw;
        margin-top: 40px;
    }

    .designTableColumn {
        border: solid 1px #FFF;
        text-align: center;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .overImageLogo {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .overImageLogoPart {
        position: absolute;
        opacity: 0.7;
    }

    .imageForwardRotation {
        animation: forward_rotation 18s linear infinite;
    }

    .imageReverseRotation {
        animation: reverse_rotation 32s linear infinite;
    }

    @keyframes forward_rotation
    {
        from {transform: rotate(0deg);}
        to {transform: rotate(359deg);}
    }

    @keyframes reverse_rotation
    {
        from {transform: rotate(0deg);}
        to {transform: rotate(-359deg);}
    }

</style>