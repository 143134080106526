<template>
    <div class="service_view" v-if="'ServiceTab' in serviceData">
        <div class="top_image_banner" :style="'background-image: url(' + imageUrl + serviceData.banner + ');'">
            <span class="banner_title">{{serviceData.title}}</span>
            <span class="banner_desc">{{serviceData.subtitle}}</span>
        </div>
        <div class="service_tab">
            <div v-for="(item, index) in serviceData.ServiceTab" :key="index"
                 :class="'service_tab_item ' + (item.idx === activeIndex ? 'service_tab_item_selected' : '') "
                 @click="activeIndex = item.idx">
                <el-icon>
                    <component :is="item.icon"></component>
                </el-icon>
                <span >{{item.name}}</span>
            </div>
        </div>

        <div v-if="activeIndex === serviceData.ServiceTab[0].idx" class="customer_service wow animate__animated animate__fadeInUp">
            <el-table :data="serviceData.CustomerServiceData" stripe border style="width: 100%">
                <el-table-column prop="title" :label="serviceData.CustomerServiceLabel.title" width="180" />
                <el-table-column prop="desc" :label="serviceData.CustomerServiceLabel.desc" />
            </el-table>
            <span class="tips">
                {{serviceData.CustomerServiceLabel.tips}}
            </span>
        </div>
        <div class="customer_service wow animate__animated animate__fadeInUp" v-if="activeIndex === serviceData.ServiceTab[1].idx">
            <el-collapse class="collapse" v-model="supportActiveName" accordion>
                <el-collapse-item v-for="(item,index) in serviceData.TechnicalSupportData" :key="index"
                                  :title="item.title" :name="item.title"
                                  class="collapse_item">
                    <div v-for="(item,index) in item.desc" :key='index'>
                        <span v-if="item.url === ''">{{item.title}}</span>
                        <el-link v-else :href="item.url" target="_blank">{{item.title}}</el-link>
                    </div>
                </el-collapse-item>
            </el-collapse>
            <span class="tips">
                {{serviceData.TechnicalSupportLabel.tips}}
            </span>
        </div>
        <div class="customer_service wow animate__animated animate__fadeInUp" v-if="activeIndex === serviceData.ServiceTab[2].idx">
            <el-collapse class="collapse" v-model="QuestionActiveName" accordion>
                <el-collapse-item v-for="(item,index) in serviceData.QuestionData" :key="index"
                                  :title="item.title" :name="item.title"
                                  class="collapse_item">
                    <div v-for="(item,index) in item.desc" :key='index'>
                        <span v-if="item.url === ''">{{item.title}}</span>
                        <el-link style="color:#CFA88C;" v-else :href="item.url" target="_blank">{{item.title}}</el-link>
                    </div>
                </el-collapse-item>
            </el-collapse>
            <span class="tips">
                {{serviceData.QuestionLabel.tips}}
            </span>
        </div>
        <div class="customer_service wow animate__animated animate__fadeInUp" v-if="activeIndex === serviceData.ServiceTab[3].idx">
            <div class="map_row">
                <div class="location_tab">
                    <div v-for="(item,index) in serviceData.ContactLabel" :key="index"
                          :class="'location_tab_item ' + (currentLocationIndex === index ? 'location_tab_item_selected' : '') "
                          @click="onLocationTabClicked(index)">
                        <span>{{item.name}}</span>
                    </div>
                </div>
                <baidu-map
                    class="map_row_top"
                    :mapClick="false"
                    :center="{ lng: serviceData.ContactLabel[currentLocationIndex].lng, lat: serviceData.ContactLabel[currentLocationIndex].lat }"
                    :zoom="18"
                >
                    <bm-marker :position="{lng: serviceData.ContactLabel[currentLocationIndex].lng, lat: serviceData.ContactLabel[currentLocationIndex].lat}"
                                @click="onMarkerClicked(currentLocationIndex)"
                    >
                        <bm-info-window :show="true" :width="100" :closeOnClick="false" @clickclose="infoWindowClose">
                            <div class="map_info">
                                <span style="margin-bottom: 5px;font-size: 14px">{{serviceData.ContactLabel[currentLocationIndex].name}}</span>
                                <span style="font-size: 12px;color:#888;"><el-icon style=";margin-right: 5px"><Location /></el-icon>{{serviceData.ContactLabel[currentLocationIndex].address}}</span>
                                <span style="font-size: 12px;color:#888;"><el-icon style=";margin-right: 5px"><Phone /></el-icon>{{serviceData.ContactLabel[currentLocationIndex].phone}}</span>
                                <block v-if="serviceData.ContactLabel[currentLocationIndex].email.length > 0">
                                    <span style="font-size: 12px;color:#888;"><el-icon style=";margin-right: 5px"><Message /></el-icon>{{serviceData.ContactLabel[currentLocationIndex].email}}</span>
                                </block>
                            </div>
                        </bm-info-window>
                    </bm-marker>
                </baidu-map>
            </div>

        </div>
        <div class="customer_service wow animate__animated animate__fadeInUp" v-if="activeIndex === serviceData.ServiceTab[4].idx">
            <el-image
                style="width: 30%"
                :src="imageUrl + serviceData.DownloadCategory.contact"
                fit="contain">
            </el-image>
            <span style="font-size: 24px;">{{serviceData.DownloadCategory.title}}</span>
        </div>

    </div>

</template>

<script>
import {Swiper, SwiperSlide} from "swiper/vue";
import {Location,Phone,Message} from "@element-plus/icons-vue";
import {ImageOSSUrl} from "@/js/netApi";

export default {
    name: "ServiceComponent",
    components: {
        Location,
        Phone,
        Message,
        Swiper,
        SwiperSlide
    },
    watch: {
        'defaultIndex': function () {
            this.activeIndex = this.defaultIndex
        }
    },
    props: {
        'defaultIndex': {
          type: String,
          default: ''
        },
        'webData': {},
        'type': {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            activeIndex: '4-1',
            supportActiveName: '',
            QuestionActiveName: '',
            imageUrl: ImageOSSUrl,
            mapEditForm:{
                address: '',
                lng:0,
                lat:0
            },
            currentLocationIndex: 0,
            selectedItem: '0',
            infoShow: true,
            serviceData: {}
        }
    },
    beforeRouteEnter() {
        window.scrollTo(0,0)
    },
    mounted() {
        this.activeIndex = this.defaultIndex
        this.serviceData = this.webData
    },
    methods: {
        onBannerClicked(idx,url,type) {
            console.log(idx)
            console.log(url)
            console.log(type)
        },

        onLocationTabClicked(index) {
            this.currentLocationIndex = index
            this.infoShow = true
        },

        infoWindowClose() {
            this.infoShow = false
        },

        onMarkerClicked(index) {
            this.currentLocationIndex = index
            this.infoShow = true
        },

        onCategoryItemClicked(index)  {
            this.selectedItem = index
        },

        onDownloadItem(url)  {
            console.log(url)
        }
    }
}
</script>

<style scoped>
    .service_view {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
    }

    .top_image_banner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100vh;
        background-repeat: no-repeat;
        background-position: center center;
    }

    .banner_title {
        color: #FFF;
        font-size: 72px;
    }

    .banner_desc {
        color: #FFF;
        font-size: 48px;
    }

    .slideItem {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        background-size:cover;
        background-repeat: no-repeat;
        color: #FFF;
        font-size: 18px;
        width: 100%;
        height: 100%;
    }

    .service_tab {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 60%;
        margin-top: 80px;
        margin-bottom: 80px;
    }

    .customer_service {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 60%;
        margin-bottom: 80px;
    }

    .support_row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .tips {
        width: 70%;
        margin-top: 40px;
        margin-bottom: 40px;
        color: #888;
        font-size: 12px;
    }

    .collapse {
        border-top: none;
        border-bottom: none;
        width: 100%;
        text-align: center;
    }

    .collapse_item {
        display: inline-block;
        box-shadow: 0 3px 10px 0 #0000001F;
        margin-bottom: 20px;
        padding: 20px;
        color: #333;
        font-size: 18px;
        width: 40%;
        vertical-align: top;
        margin-right: 20px;
        border-radius: 10px;
    }

    :deep(.el-collapse-item__header) {
        border-bottom: none !important;
        font-size: 16px;
        color: #333;
    }

    :deep(.el-collapse-item__content) {
        color: #888;
        text-align: start;
    }

    .service_tab_item {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        width: 12%;
        color: #888888;
        font-size: 18px;
        padding: 10px 20px 10px 20px;
        cursor: pointer;
    }

    .service_tab_item:hover {
        background-color: #CFA88C30;
        border-radius: 100px;
        color: #CFA88C;
    }

    .service_tab_item_selected {
        background-color: #CFA88C30;
        border-radius: 100px;
        color: #CFA88C;
    }

    .location_tab {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        margin-bottom: 20px;
    }

    .map_row_top {
        width: 100%;
        height: 40vw;
        box-shadow: 0 3px 10px 0 #0000001F;
    }

    .location_tab_item {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 40%;
        text-align: center;
        font-size: 20px;
        color: #888;
        padding: 20px;
        cursor: pointer;

    }

    .location_tab_item_selected {
        border-radius: 5px;
        box-shadow: 0 3px 10px 0 #0000001F;
        color: #333
    }

    .map_row {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        margin-bottom: 80px;
    }

    .map_row_left {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 35%;
        font-size: 14px;
        color: #888888;
        padding: 40px 20px 20px 20px;
        box-sizing: border-box;
    }

    .map_row_left_item {
        margin-bottom: 20px;
    }

    .map_row_right {
        width: 65%;
        height: 500px;
    }

    .map_info {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        line-height: 1.5em;
        padding-bottom: 5px;
    }

    .download_view {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        min-height: 400px;
    }

    .category-view {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 20%;
        border: 1px solid #E7E9F3;
    }

    .download-item-view {
        width: 75%;
    }

    .category_item {
        font-size: 14px;
        border-top: 1px solid #E7E9F3;
        width: 95%;
        color: #818181;
        padding: 10px 0;
        margin-left: 6px;
    }

    .category_item_selected {
        color: #CFA88C;
        font-weight: bold;
    }

    .category_item:hover {
        color: #CFA88C;
        font-weight: bold;
        cursor: pointer;
    }

    .download-item {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        padding: 20px 0;
        color: #333333;
    }

    .download-item:hover {
        cursor: pointer;
        color: #CFA88C;
    }

    .download-item-top {
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        overflow: hidden;
    }

    .download-item-bottom {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding: 10px;
        box-sizing: border-box;

    }

    .download-item-name {
        font-size: 16px;
        margin-bottom: 4px;
    }


</style>
<style>
    .BMap_pop>img {
        width: 0 !important;
        height: 0 !important;
    }
</style>