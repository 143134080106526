<template>
    <el-container ref="app" class="container">
        <el-header class="header-container" height="80px">
            <HeaderComponent @onLangaugeChanged="onLangaugeChanged"
                             :activeIndex="currentSubPage" :need-scroll="needScore" :defaultLang="languageCode"
                             @onSearched="onSearched" @onMenuClicked="onMenuClicked" :webData="webData?.Header" />
        </el-header>
        <el-main>
            <router-view name="MainComponent" @onMenuClicked="onMenuClicked" :webData="webData?.Main" />
            <router-view name="ProductList" :defaultIndex="productIdx" :productData="productData" @onSearched="onSearched" />
            <router-view name="ProductItemComponent" />
            <router-view name="ServiceComponent" :defaultIndex="serviceIdx" :webData="webData?.Service" />
            <router-view name="DesignComponent" :webData="webData?.Design" />
            <router-view name="DesignFlowComponent" :webData="webData?.DesignFlow" />
            <router-view name="CaseComponent" :webData="webData?.Case" />
            <router-view name="ProjectComponent" :webData="webData?.Project" />
            <router-view name="AboutComponent" :defaultIndex="aboutIdx" :webData="webData?.About" />
            <router-view name="SearchResultComponent" :keyword="searchKeyword" :productData="productData" />
        </el-main>
        <el-footer>
            <FooterComponent @onMenuClicked="onMenuClicked" :data="webData?.Footer" />
        </el-footer>
        <el-backtop :right="100" :bottom="100" />
    </el-container>
</template>

<script>
import HeaderComponent from '@/components/HeaderComponent'
import FooterComponent from '@/components/FooterComponent'
import LocalStorage from "@/js/localStorage"
import {DataOSSURL} from "@/js/netApi"
import device from "current-device";

export default {
    name: 'App',

    components: {
        HeaderComponent,
        FooterComponent,
    },
    data() {
        return {
            currentPage: '1',
            currentSubPage: '1',
            searchKeyword: '',
            languageCode: 'zh',

            productIdx: '2-1-0',
            designIdx: '3-1',
            caseIdx: '6-1',
            serviceIdx: '4-1',
            aboutIdx: '5-1',

            needScore: true,

            webData: {},
            productData: {}
        }
    },
    mounted() {


    },
    beforeMount()  {
        if (device.mobile()) {
            //window.location.href = "https://www.baidu.com"
            //return
        }
        this.getAgentLang()
        this.$router.beforeResolve((to, from, next) => {
            // 在这里获取路由信息并进行相应操作
            this.setCurrentRouteFlag(to)
            next();
        });
    },
    methods: {
        onLangaugeChanged(lang) {
            if (this.languageCode === lang) {
                return
            }

            if ((lang === 'zh') || (lang === 'cht') || (lang === 'en')) {
                LocalStorage.setStorage('lang', lang)
                window.location.href = "/"
            }
        },

        setCurrentRouteFlag(route)  {

            let routeName = route.name

            if (routeName ===  'product') {
                this.currentSubPage = '2-1-0'
            } else if (routeName === 'productItem') {
                this.currentSubPage = '2-1-0'
            } else if (routeName ===  'design') {
                this.currentSubPage = '3-1'
            } else if (routeName ===  'designFlow') {
                this.currentSubPage = '3-2'
            } else if (routeName ===  'case') {
                this.currentSubPage = '6-1'
            } else if (routeName ===  'project') {
                this.currentSubPage = '6-2'
            } else if (routeName ===  'service') {
                this.currentSubPage = '4-1'
            } else if (routeName ===  'about') {
                this.currentSubPage = '5-1'
            } else {
                this.currentSubPage = '1'
            }

        },

        onSearched(keyword) {
            this.currentPage = '8'
            this.searchKeyword = keyword

            let url = this.$router.resolve({
                    path:'/search',
                    name: 'search',
                    query: {
                        keyword: this.searchKeyword
                    }
                })

            window.open(url.href, '_blank')



            //this.$router.push('/search')
        },

        onMenuClicked(idx, subIdx, url, blank="") {
            this.currentPage = idx
            this.currentSubPage = subIdx

            let routeName = ''

            console.log(url)

            this.needScore = true

            if (idx === '2') {
                this.productIdx = subIdx
                routeName = "product"
            } else if (idx === '3') {
                this.designIdx = subIdx
                if (subIdx === '3-1') {
                    routeName = "design"
                } else if (subIdx === '3-2') {
                    routeName = "designFlow"
                }
            } else if (idx === '6') {
                this.caseIdx = subIdx
                if (subIdx === '6-1') {
                    routeName = "case"
                } else if (subIdx === '6-2') {
                    routeName = "project"
                }
            } else if (idx === '4') {
                this.serviceIdx = subIdx
                routeName = "service"
            } else if (idx === '5') {
                this.aboutIdx = subIdx
                routeName = "about"
            } else {
                this.needScore = true
            }

            if (blank === '') {
                this.$router.push('/' + routeName)
            } else {
                LocalStorage.setStorage('subIdx', subIdx)

                let destUrl = this.$router.resolve({
                    path: '/' + routeName,
                })

                window.open(destUrl.href, blank)
            }

        },

        getAgentLang(lang = "") {
            if ((!lang) || (lang === "") || ((lang !== "zh") && (lang !== "en") && (lang !== "cht"))) {
                lang = LocalStorage.getStorage('lang')
                if ((!lang) || (lang === "") || ((lang !== "zh") && (lang !== "en") && (lang !== "cht"))) {
                    lang = "zh"
                }
            }

            LocalStorage.setStorage('lang', lang)

            this.languageCode = lang
            this.getWebData(this.languageCode)
            this.getProductData(this.languageCode)

        },

        getWebData(lang) {
            let url = DataOSSURL + 'web_data_' + lang + '.json'
            //let url = require('@/assets/json/web_data_zh.json')

            let xhr = new XMLHttpRequest();
            xhr.open('GET', url, false);  // 使用 false 参数使请求变成同步调用
            xhr.send();

            if (xhr.status === 200) {
                this.webData = JSON.parse(xhr.response);
            } else {
                // 处理请求失败的情况
                console.log( '请求失败：' + xhr.status);
            }
        },

        getProductData(lang) {
            let url = DataOSSURL + 'product_' + lang + '.json'

            let xhr = new XMLHttpRequest();
            xhr.open('GET', url, false);  // 使用 false 参数使请求变成同步调用
            xhr.send();

            if (xhr.status === 200) {
                this.productData = JSON.parse(xhr.response);
            } else {
                // 处理请求失败的情况
                console.log( '请求失败：' + xhr.status);
            }
        }
    }



}
</script>

<style>
#app {
    font-family: Microsoft YaHei,PingFang SC,Heiti SC,tahoma,arial,Hiragino Sans GB,sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

}

:root {
    --el-color-primary: #CFA88C !important;

}

* {
    margin: 0;
    --el-color-primary: #CFA88C !important;
    --el-menu-hover-bg-color: #FFFFFF00;
    --el-menu-active-color: #CFA88C;
    --el-dialog-title-font-size: 24px !important;
}

.el-container {
    position: relative;
}

.el-header {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1000;
    width: 100%;
    padding: 0 !important;
    box-sizing: unset !important;
}



.el-main {
    padding: 0 !important;
}

.el-footer {
    padding: 0 !important;
}

.el-input__wrapper {
    box-shadow: 0 0 0 0px !important;
    border-radius: 100px !important;
}

.el-link.el-link--default {
    color: #d0d0d080;
}

.mouse-cover-canvas {
    background-color: #CCCCCCCC;
    backdrop-filter: blur(5px);
}

</style>
