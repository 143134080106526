<template>
    <el-row :gutter="20" type="flex" justify="space-between" align="middle" class="head-container" id="header">
        <el-col :span="2">
            <div class="head_item" style="justify-content: flex-start;align-items: center;margin-top: 4px;margin-bottom: 4px;">
                <el-image
                    class="logo-image"
                    id="logo-image"
                    :src="imageUrl + headData.logo"
                    fit="contain">
                </el-image>
            </div>
        </el-col>
        <el-col :span="14" >
            <div class="head_item" style="justify-content: end;">
                <el-menu :default-active="selectedIndex"  :text-color="normalTextColor" :active-text-color="activeTextColor"
                         mode="horizontal" :ellipsis="false">
                    <div v-for="(item, index) in headData.menus" :key="index">
                        <div v-if="item.items.length > 0">
                            <el-sub-menu :index="item.idx" name="menu-item" :data-menu-idx="item.idx">
                                <template v-slot:title ><span class="menu_text">{{item.title}}</span></template>
                                <div v-for="(subItem,subIdx) in item.items" :key="subIdx">
                                    <el-menu-item v-if="!(subItem.hasOwnProperty('enable')) && !subItem.enable"  style="border-bottom: none !important;"
                                                   :index="subItem.idx" @click="onMenuClicked(item.idx, subItem.idx, subItem.url)">
                                        <template v-slot:title>
                                            <el-icon name="menu-item" :data-menu-idx="subItem.idx">
                                                <component :is="subItem.icon"></component>
                                            </el-icon>
                                            <span :data-menu-idx="subItem.idx">{{subItem.title}}</span>
                                        </template>
                                    </el-menu-item>
                                </div>

                            </el-sub-menu>
                        </div>
                        <div v-else>
                            <el-menu-item name="menu-item" :index="item.idx" :data-menu-idx="item.idx" class="menu_text" @click="onMenuClicked(item.idx, item.idx, item.url)">{{item.title}}</el-menu-item>
                        </div>
                    </div>
                </el-menu>
            </div>
        </el-col>
        <el-col :span="5">
            <div class="head_item" style="justify-content: flex-end">
                <el-tooltip :content="headData.search.tips" placement="bottom" effect="light">
                    <el-icon class="head_icon" @click="showSearch = !showSearch" >
                        <component :is="headData.search.icon"></component>
                    </el-icon>
                </el-tooltip>
                <el-input
                    class="animate__animated animate__fadeIn"
                    v-show="showSearch"
                    style="margin-left: 10px;border-bottom: solid 1px #0D0D0D80"
                    :placeholder="headData.search.placeholder"
                    v-model="searchKeyword"
                    @blur="showSearch = false"
                    @change="onSearch"
                    clearable>
                </el-input>
                <el-tooltip :content="headData?.service.tips" placement="bottom" effect="light" style="margin-left: 20px">
                    <el-icon class="head_icon" @click="onMenuClicked('4','4-4','')">
                        <component :is="headData.service.icon"></component>
                    </el-icon>
                </el-tooltip>

            </div>
        </el-col>
        <el-col :span="3">
            <div class="head_item">
                <el-select v-model="selectedLang.label"
                           :placeholder="headData.language.placeholder"
                           @change="onSelectLang"
                           style="width: 160px">
                    <template v-slot:prefix>
                        <div class="head_item">
                            <el-image
                                style="height: 12px"
                                :src="imageUrl + selectedLang.icon"
                                fit="contain">
                            </el-image>
                        </div>

                    </template>
                    <el-option v-for="item in headData.language.items" :key="item.value" :label="item.label" :value="item.value">
                        <el-image
                            style="height: 12px;margin-right:5px"
                            :src="imageUrl + item.icon"
                            fit="contain">
                        </el-image>
                        <span>{{ item.label }}</span>
                    </el-option>
                </el-select>
            </div>
        </el-col>
    </el-row>
</template>

<script>
import {Headset, MoreFilled, Notebook, Trophy} from "@element-plus/icons-vue";
import {ImageOSSUrl} from "@/js/netApi";

export default {
    name: "HeaderComponent",
    components: {
        Trophy,
        Notebook,
        Headset,
        MoreFilled

    },
    props: {
        webData: {
            default: {}
        },
        activeIndex: {
            type: String,
            default: '1'
        },
        defaultLang: {
            default: {
                name: 'zh',
                icon: 'lang_zh_cn.png'
            }
        },
        needScroll: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            searchKeyword: '',
            selectedIndex: this.activeIndex,
            showSearch: false,
            selectedLang: {},
            imageUrl: ImageOSSUrl,
            normalTextColor: '#FFFFFF',
            activeTextColor: '#FFFFFF',
            scoreFlag: true,
            langList: [],
            headData: {}
        };
    },
    beforeMount()  {
        this.headData = this.webData
    },
    mounted() {


        this.selectedIndex = this.activeIndex
        this.langList = this.headData.language.items

        this.getSelectLangByLangCode(this.defaultLang)

        window.onscroll = this.onScrolled

        this.$nextTick(() => {
            // 在$nextTick回调中尝试获取DOM元素
            /*const logo = document.getElementById('logo-image');
            if (logo) {
                logo.style.filter = `brightness(0) invert(1)`;
            }*/

            this.updateMenuStyle()
        });
    },
    watch: {
        //方法1
        "activeIndex"(newVal) {
            this.selectedIndex = newVal + ''
            this.updateMenuStyle()
        },
        "defaultLang"(newVal, oldVal) {
            this.selectedLang = this.getSelectLangByLangCode(newVal)
            console.log(oldVal)
        },
        "needScroll"(newVal, oldVal) {
            this.scoreFlag = newVal

            if (this.scoreFlag) {
                window.onscroll = this.onScrolled
            } else {
                window.onscroll = null
                //this.setNormalStyle()
            }

            console.log(oldVal)
        },
        "webData": function (newVal, oldVal) {
            this.headData = newVal

            console.log(oldVal)
        }
    },
    methods: {
        getSelectLangByLangCode(lang) {
            for (let i = 0 ; i < this.langList.length ; i++) {
                if (this.langList[i].value === lang) {
                    this.selectedLang = this.langList[i]
                }
            }
        },
        updateMenuStyle() {
            let elements = document.getElementsByName('menu-item');
            //let elements = document.querySelectorAll('.menu_text'); // 选择所有需要更改样式的元素

            let scrollDistance = window.scrollY;

            let mainIndx = this.selectedIndex
            let selectIdx = this.selectedIndex.split('-')[0]

            if (scrollDistance > 280) {
                this.normalTextColor = '#0D0D0D80'
                this.activeTextColor = '#CFA88C'

                elements.forEach(function(element) {
                    if (selectIdx === element.dataset.menuIdx) {
                        element.style.color = '#CFA88C'
                        element.style.fontWeight = 'bold'
                        //element.style.borderBottom = 'solid 2px #CFA88C' ;
                    } else if (element.dataset.menuIdx.length >= 3) {
                        if (mainIndx === element.dataset.menuIdx) {
                            element.style.color = '#CFA88C'
                            element.style.fontWeight = 'bold'
                        } else {
                            element.style.color = '#0D0D0D80'
                            element.style.fontWeight = 'normal'
                        }

                    } else {
                        element.style.color = '#0D0D0D80'
                        element.style.fontWeight = 'normal'
                        //element.style.borderBottom = 'none !important;'
                    }
                });

            } else {

                this.normalTextColor = '#FFFFFF80'
                this.activeTextColor = '#FFFFFF'

                elements.forEach(function(element) {
                    if (selectIdx === element.dataset.menuIdx) {
                            element.style.color = '#FFFFFF'
                            element.style.fontWeight = 'bold'
                            //element.style.borderBottom = 'solid 2px #FFFFFF' ;
                    } else if (element.dataset.menuIdx.length >= 3) {
                        if (mainIndx === element.dataset.menuIdx) {
                            element.style.color = '#CFA88C'
                            element.style.fontWeight = 'bold'
                        } else {
                            element.style.color = '#0D0D0D80'
                            element.style.fontWeight = 'normal'
                        }

                    } else {
                        element.style.color = '#FFFFFF80'
                        element.style.fontWeight = 'normal'
                        //element.style.borderBottom = 'none !important;'
                    }
                });
            }
        },
        /*
        setNormalStyle() {
            let header = document.getElementById('header');
            //let logo = document.getElementById('logo-image');

            header.style.backgroundColor = 'rgba(255, 255, 255, 1)'
            //logo.style.filter = 'brightness(1) invert(0)';

            let elements = document.querySelectorAll('.menu_text'); // 选择所有需要更改样式的元素
            elements.forEach(function(element) {
                if (element.classList.contains('is-active')) {

                    if (element.classList.contains('el-menu-item')) {

                        element.style.borderBottom = `solid 2px #CFA88C` ;
                    }
                    element.style.color = '#CFA88C';
                } else {
                    element.style.color = '#0D0D0D';
                }
            });

        },

        */

        onScrolled() {
            let header = document.getElementById('header');
            let elements = document.querySelectorAll('.head_icon');

            let scrollDistance = window.scrollY;

            if (scrollDistance > 280) {
                header.style.backgroundColor = '#FFFFFF'
                elements.forEach(function(element) {
                    element.style.color = '#0D0D0D80'
                })
            } else {
                header.style.backgroundColor = '#FFFFFF00'
                elements.forEach(function(element) {
                    element.style.color = '#FFF'
                })
            }

            this.updateMenuStyle()

            /*
            let transparency = scrollDistance / 80;
            header.style.backgroundColor = `rgba(255, 255, 255, ${transparency})`;

            logo.style.filter = `brightness(${transparency}) invert(${1 - transparency})`;

            let normal_red = Math.round(255 - transparency * 130 < 13 ? 13 : 255 - transparency * 130).toString(16).padStart(2, '0');
            let normal_green = Math.round(255 - transparency * 130 < 13 ? 13 : 255 - transparency * 130).toString(16).padStart(2, '0');
            let normal_blue = Math.round(255 - transparency * 130 < 13 ? 13 : 255 - transparency * 130).toString(16).padStart(2, '0');

            this.normalTextColor = `#${normal_red}${normal_green}${normal_blue}`;

            let active_red = Math.round(255 - transparency * 120 < 207 ? 207 : 255 - transparency * 120).toString(16).padStart(2, '0');
            let active_green = Math.round(255 - transparency * 106 < 168 ? 168 : 255 - transparency * 106).toString(16).padStart(2, '0');
            let active_blue = Math.round(255 - transparency * 78 < 135 ? 135 : 255 - transparency * 78).toString(16).padStart(2, '0');

            let elements = document.querySelectorAll('.menu_text'); // 选择所有需要更改样式的元素
            elements.forEach(function(element) {
                if (element.classList.contains('is-active')) {
                    console.log(element)

                    if (element.classList.contains('el-menu-item')) {

                        element.style.borderBottom = `solid 2px #${active_red}${active_green}${active_blue} !important;` ;
                        element.style.color = `#${active_red}${active_green}${active_blue} !important;`;
                    }
                } else {
                    element.style.color = `#${normal_red}${normal_green}${normal_blue} !important;`;
                }
            });
            */



        },
        onSelectLang(item) {
            this.langList.forEach((langObj)=> {
                if (!langObj) {
                    return
                }

                if (langObj.value === item) {
                    //this.selectedLang.icon = langObj.icon
                    this.$emit('onLangaugeChanged', item)

                }
            });
        },
        onSearch(keyword) {
            if (keyword.length > 0) {
                this.$emit('onSearched', keyword)
                this.showSearch = false
            }

        },
        onMenuClicked(idx, subIdx, url) {
            this.$emit('onMenuClicked', idx, subIdx, url)
        }
    }
}
</script>

<style scoped>

* {
    --el-menu-hover-text-color: #CFA88C !important;
    --el-menu-text-color: #0D0D0D80 !important;
}

.head-container {
    padding: 0 10px;
    background-color: rgba(255, 255, 255, 0);
    transition: 0.5s;
}

.el-sub-menu .is-active {
    border-bottom: solid 0px #FFF !important;
}

.el-menu {
    background-color: #FFFFFF00 !important;
}

.el-menu .is-active {
    --el-menu-text-color: #0D0D0D80 !important;
    /*border-bottom: solid 2px #FFF !important;*/
}

.el-select-dropdown__item.hover {
    color: #0D0D0D80 !important;
    background-color: #CFA88C20 !important;
}

.el-menu.el-menu--horizontal {
    color: #CFA88C;
    border-bottom: solid 0px #fff;

}

.head_item {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
}

.menu_text {
    font-size: 16px !important;
}

.menu_text.is-active {
    color: #FFF ;
}

.head_icon {
    /*color: #0D0D0D80 !important;*/
    color: #FFF;
    font-size: 20px !important;
    margin-left: 20px;
    cursor: pointer;
}

.logo-image {
    height: 64px;
    /*filter: brightness(0) invert(1)*/
}

.el-tooltip__popper.is-light {
    color: #0D0D0D80;
    border: solid 1px #0D0D0D80 ;
}

.el-select-dropdown__item.selected {
    color: #CFA88C;
}

.el-select-dropdown__item.hover {
    color: white;
    background-color: #CFA88C;
    font-weight: 0;
}


</style>