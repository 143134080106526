<template>
    <div class="footer">
        <div class="footer_link_view">
            <div class="footer_link_view_left">
                <div class="footer_link_view_item" v-for="(item, index) in data.menus" :key=index >
                    <span class="item_title">{{item.title}}</span>
                    <div v-for="(link, idx) in item.items" :key="idx">
                        <span class="item_link" v-if="!(link.hasOwnProperty('enable')) && !link.enable"
                            @click="onMenuClicked(item.idx, link.idx, link.url)">
                            {{link.title}}
                        </span>
                    </div>
                </div>
            </div>
            <div class="footer_link_view_shop">
                <el-image
                    style="width: 25%;"
                    :src="imageUrl + data.logo"
                    fit="contain">
                </el-image>
                <span class="copyright">{{data.copyright}}</span>
            </div>
        </div>

    </div>
</template>

<script>
import {ImageOSSUrl} from "@/js/netApi";

export default {
    name: "FooterComponent",
    props: {
        'data': {
            default: {
                menus: [
                    {
                        idx: '2',
                        title: '才展产品',
                        url: '#',
                        items: [
                            {
                                idx: '2-1',
                                title: '商业照明',
                                url: '#',
                                icon: 'OfficeBuilding'
                            },
                            {
                                idx: '2-2',
                                title: '专业照明',
                                url: '#',
                                icon: 'Aim'
                            },
                            {
                                idx: '2-3',
                                title: '景观亮化',
                                url: '#',
                                icon: 'Sunrise'
                            },
                            {
                                idx: '2-4',
                                title: '了解更多',
                                url: '#',
                                icon: 'MoreFilled'
                            },
                        ]
                    },
                    {
                        idx: '3',
                        title: '照明设计',
                        url: '#',
                        items: [
                            {
                                idx: '3-1',
                                title: '照明介绍',
                                url: '#',
                                icon: 'Reading'
                            },
                            {
                                idx: '3-2',
                                title: '工程案例',
                                url: '#',
                                icon: 'DataAnalysis'
                            }
                        ]
                    },
                    {
                        idx: '4',
                        title: '服务中心',
                        url: '#',
                        items: [
                            {
                                idx: '4-1',
                                title: '售后服务',
                                url: '#',
                                icon: 'User'
                            },
                            {
                                idx: '4-2',
                                title: '技术支持',
                                url: '#',
                                icon: 'Headset'
                            },
                            {
                                idx: '4-3',
                                title: '常见问题',
                                url: '#',
                                icon: 'Bell'
                            },
                            {
                                idx: '4-4',
                                title: '联系我们',
                                url: '#',
                                icon: 'Phone'
                            }
                        ]
                    },
                    {
                        idx: '5',
                        title: '关于才展',
                        url: '#',
                        items: [
                            {
                                idx: '5-1',
                                title: '公司介绍',
                                url: '#',
                                icon: 'Notebook'
                            },
                            {
                                idx: '5-2',
                                title: '研发团队',
                                url: '#',
                                icon: 'Trophy'
                            },
                            {
                                idx: '5-3',
                                title: '营销方式',
                                url: '#',
                                icon: 'Filter'
                            },
                            {
                                idx: '5-4',
                                title: '人才招聘',
                                url: '#',
                                icon: 'View'
                            }
                        ]
                    },
                ],
                shop: {
                    title: '官方旗舰店',
                    icon: 'albb.png',
                    url: 'https://geniuslighting.en.alibaba.com/index.html',
                },
                logo: 'logo_no_text.png',
                copyright: '© 2012-2023 geniuslighting.com. All rights reserved.'
            }
        }
    },
    methods: {
        onMenuClicked(idx, subIdx, url) {
            this.$emit('onMenuClicked', idx, subIdx, url)
        }
    },
    data() {
        return {
            imageUrl: ImageOSSUrl
        }
    }
}
</script>

<style scoped>
.footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    background-color: #333333;
    box-sizing: border-box;
    padding:40px;
}

.footer_link_view {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 90%;
    margin-bottom: 80px;
}

.footer_link_view_left {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 60%;
}

.footer_link_view_item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

}

.footer_link_view_shop {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.item_title {
    font-size: 16px;
    color: #ddd;
    font-weight: bold;
    margin-bottom: 20px;
}

.item_link {
    color: #d0d0d080;
    font-size: 14px;
    margin-bottom: 10px;
    cursor: pointer;
}

.item_link:hover {
    color: #CFA88C;
}

.copyright {
    color: #d0d0d080;
}
</style>