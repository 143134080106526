<template>
    <swiper effect="fade"
            :autoplay="{
                delay: 3000,
                disableOnInteraction: false
            }"
            :loop="true"
            :modules="modules"
            :pagination="{
                clickable: true,
                observer: true,//开启动态检查器，监测swiper和slide
                observeParents: true//监测Swiper 的祖/父元素
            }"
            style="width:100%;"
    >
        <swiper-slide v-for="(item, index) in mainData.SwiperBanner" :key="index">
            <el-image
                :src="imageUrl + item.image"
                @click="onBannerClicked(item.idx, item.subIdx, item.url, item.type)"
                fit="contain">
            </el-image>
        </swiper-slide>
    </swiper>
    <ProductComponent :webData="mainData.ProductTab" @onProductItemClicked="onProductItemClicked" />
    <div class="intro" :style="'height: 784px; background-image: url(' + imageUrl + mainData.Intro?.desc.bg + ')'">
        <div class="intro_desc wow animate__animated animate__fadeInUp">
            <span style="font-size: 36px">{{mainData.Intro?.desc.title}}</span>
            <span v-for="(item, index) in mainData.Intro?.desc.contents" :key="index" class="intro_desc_content">
                &emsp;&emsp;{{item}}
            </span>
        </div>
    </div>
    <div class="intro" :style="'justify-content: flex-start;height: 842px; margin-top:0px; background-image: url(' + imageUrl + mainData.Intro?.market.bg + ')'">
        <div class="intro_market wow animate__animated animate__fadeInUp">
            <span style="font-size: 36px">{{mainData.Intro?.market.title}}</span>
            <span v-for="(item, index) in mainData.Intro?.market.contents" :key="index" class="intro_market_content">
                &emsp;&emsp;{{item}}
            </span>
        </div>
    </div>
    <div class="case_view wow animate__animated animate__fadeInUp">
        <span class="title_text" >{{'Intro' in mainData ? mainData.CaseView.title : ''}}</span>
        <span class="sep" />
        <!--<span class="subtitle_text">{{data.CaseView.subtitle}}</span>-->
        <el-carousel v-if="'Intro' in mainData" :interval="4000" type="card" height="500px" indicator-position="none" style="width: 80%">

            <el-carousel-item v-for="(item, index) in mainData.CaseView.items" :key="index">
                <el-image
                    @click="onMenuClicked(item.idx, item.subIdx, item.url, '_blank')"
                    :src="imageUrl + item.image"
                    fit="cover">
                </el-image>
            </el-carousel-item>

        </el-carousel>
    </div>
    <div class="service_block" :style="'background-image: url(' + imageUrl + mainData.CustomService?.bg + ');'">
        <div class="service_block_left wow animate__animated animate__fadeInUp">
            <div class="service_block_left_row" v-for="(item, index) in mainData.CustomService?.items" :key="index" >
                <el-image style="width: 40px; height: 40px; margin-right: 20px" :src="imageUrl + item.icon" fit="cover"></el-image>
                <div class="service_block_left_title">
                    <span class="service_title" :style="item.style">{{item.title}}</span>
                    <span v-if="item.subtitle !== ''" class="service_subtitle">{{item.subtitle}}</span>
                </div>
            </div>
        </div>
        <div class="service_block_right wow animate__animated animate__fadeInUp">
            <div class="service_block_right_column" v-for="(item, index) in mainData.CustomService?.official" :key="index">
                <el-image style="width: 100px; height: 100px; " :src="imageUrl + item.icon" fit="cover"></el-image>
                <span class="official_title">{{item.title}}</span>
            </div>
        </div>
    </div>
</template>

<script>

import {Swiper, SwiperSlide} from "swiper/vue";
import {ImageOSSUrl} from "@/js/netApi";


// Import Swiper styles
import 'swiper/css';
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { WOW } from 'wowjs'
import {Autoplay, EffectFade, Navigation, Pagination} from "swiper";
import ProductComponent from '@/components/ProductComponent'

export default {
    name: "MainComponent",
    components: {
        Swiper,
        SwiperSlide,
        ProductComponent
    },
    props: {
        'webData':{}
    },
    beforeRouteEnter() {

        window.scrollTo(0,0)
    },
    mounted() {

        this.mainData = this.webData

        new WOW({
            offset: 0,
            callback: () => {

            }
        }).init();
    },
    watch: {
        'webData': function () {
            this.mainData = this.webData
        }
    },
    setup() {
        return {
            modules: [EffectFade, Navigation, Pagination, Autoplay],
        };
    },
    data() {
        return {

            swiperOpt: {
                autoplay: {
                    delay: 3000
                },
                loop: true
            },
            countFlag: false,
            imageUrl: ImageOSSUrl,
            mainData: {}
        };
    },
    methods: {
        onBannerClicked(idx, subIdx, url, type) {
            if (type === '0') {
                //不跳转

            } else if (type === '1') {
                //内部跳转
                this.$emit('onMenuClicked', idx, subIdx, "", "_blank")
            } else if (type === '2') {
                //外部跳转
                window.open(url, '_blank')
            }
        },
        onMenuClicked(idx, subIdx, url, blank="") {
            this.$emit('onMenuClicked', idx, subIdx, url, blank)
        },
        onProductItemClicked(idx, subIdx) {
            this.$emit('onMenuClicked', idx, subIdx, "", "_blank")
        }

    }
}

</script>

<style>
.swiper-pagination-bullet {
    background: #FFF;
    opacity: 0.5;
}

.swiper-pagination-bullet-active {
    opacity: 1;
}

.el-main {
    padding: 0 !important;
}

.el-footer {
    padding: 0 !important;
}

.el-menu.el-menu--horizontal {
    color: #CFA88C;
    border-bottom: solid 0px #fff;
}

.head_item {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
}

.menu_text {
    font-size: 16px !important;
}

.el-tooltip__popper.is-light {
    color: #0D0D0D80;
    border: solid 1px #0D0D0D80 ;
}

.el-select-dropdown__item.selected {
    color: #CFA88C;
}

.el-select-dropdown__item.hover {
    color: white;
    background-color: #CFA88C;
    font-weight: 0;
}

.el-input__wrapper {
    box-shadow: 0 0 0 0px !important;
}



.footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    background-color: #333333;
    box-sizing: border-box;
    padding:40px;
}

.footer_link_view {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 90%;
    margin-bottom: 80px;
}

.footer_link_view_left {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 40%;
}

.footer_link_view_item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

}

.footer_link_view_shop {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.item_title {
    font-size: 16px;
    color: #ddd;
    font-weight: bold;
    margin-bottom: 20px;
}

.item_link {
    color: #d0d0d080;
    font-size: 14px;
    margin-bottom: 10px;
}

.el-link.el-link--default {
    color: #d0d0d080;
}

.copyright {
    color: #d0d0d080;
}

.intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top:80px;
    box-sizing: border-box;
    background-color: #F6F6F6;
    background-position: center;
}

.intro_desc {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    width: 55%;
    color: white;
}

.intro_desc_content {
    font-size: 18px;
    text-align: right;
    margin-top: 30px;
}

.intro_market_content {
    font-size: 18px;
    margin-top: 16px;
}

.intro_market {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #333333AA;
    color: white;
    width: 30%;
    padding: 20px;
    margin-top: 20px;
    border-bottom: 8px solid #CFA88C;
}

.intro_part {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 40vw;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    color: #FFF;
    padding-top: 40px;
    padding-bottom: 40px;
    box-sizing: border-box;
    margin-bottom: 40px;
}

.intro_part_block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.timeline_item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 35vw;
    background-color: white;
    border-radius: 0 100px 100px 0;
    box-shadow: 5px 5px 10px #888888; ;
    padding: 10px;
    overflow: hidden;
    margin-bottom: 40px;
    --animate-duration: 2s;
}

.timeline_item_left {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    width: 70%;
}

.timeline_item_title {
    font-size: 18px;
    color: #333333;
    margin-bottom: 10px;
}

.timeline_item_content {
    font-size: 14px;
    color: #888888;
}

.timeline_item_right {
    margin-right: 0px;
    transform: scale(1.8);
    margin-top: 20px;
}


.case_view {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 120px;
    padding-bottom: 120px;
    box-sizing: border-box;

}

.title_text {
    font-size: 36px;
    margin-top: 28px;
    color: #383838;
}

.sep {
    width: 68px;
    height: 3px;
    background-color: #383838;
    margin: 34px;
}

.subtitle_text {
    color: #333;
    font-size: 18px;
    margin-bottom: 60px
}

.intro_text_view {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.intro_text_view_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    margin-bottom: 80px;
}

.intro_text_view_row_left {

    width: 45%;
}

.intro_text_view_row_right {
    width: 45%;
}

.intro_text {
    font-size: 18px;
    color: #333333;
    line-height: 40px;
}

.intro_count_view {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 50%;
}

.intro_count_item {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.intro_count_text {
    font-size: 48px;
    color: #333333;
}

.intro_count_title {
    font-size: 14px;
    color: #666;
}

.service_block {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
    background-size: 100% 100%;;
    background-repeat: no-repeat;
    color: #eee;
    padding: 100px;
}

.service_block_left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.service_block_left_row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 80px;
    /*margin-top: 20px;
    padding-top: 10px;
    padding-bottom: 10px;*/
}

.service_block_right {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.service_block_right_column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-right: 20px;
}

.service_block_left_title {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.service_title {
    //font-weight: bold;
    font-size: 24px;
    white-space: pre-wrap;
}

.service_subtitle {
    font-size: 18px;
    color: #ccc;
}

.official_title {
    font-size: 16px;
    margin-top: 8px;
    text-align: center;
}


</style>