<template>
    <div class="wow animate__animated animate__fadeInUp container">
        <span class="product_type_text">{{productData.title}}</span>
        <span class="sep"></span>
        <div class="product_tab">
            <div class="product_tab_item"
                 :style="'background-image: url(' + imageUrl + item.image + ');background-repeat:no-repeat;'"
                 v-for="(item, index) in productData.items" :key="index" @click="onProductItemClicked(item.idx,item.subIdx)">
                <span class="product_tab_item_title">{{item.title}}</span>
                <span class="product_tab_item_btn">{{ transText('查看更多') }}</span>
            </div>
        </div>

    </div>
</template>

<script>
import {ImageOSSUrl, getBasicTextByLang} from "@/js/netApi";
export default {
    name: "ProductList",
    props: {
        webData: {}
    },
    data() {
        return {
            imageUrl: ImageOSSUrl,
            productData: {}
        }
    },
    mounted() {
        this.productData = this.webData
    },
    watch: {
        'webData': function () {
            this.productData = this.webData
        }
    },
    methods: {
        onProductItemClicked(idx, subIdx) {
            this.$emit('onProductItemClicked', idx, subIdx)
        },

        transText(text) {
            return getBasicTextByLang(text)
        },
    }
}
</script>

<style scoped>
    .container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        margin: 40px 0;
    }

    .product_type_text {
        font-size: 36px;
        margin-top: 28px;
        color: #383838;
    }

    .sep {
        width: 68px;
        height: 3px;
        background-color: #383838;
        margin: 34px 80px;
    }

    .product_tab {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 85%;
        flex-wrap: wrap;
        gap: 20px;
    }

    .product_tab_item {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        width: 30%;
        height: 300px;
        background-size: 100% 100%;
        background-position: center center;
        transition: all 0.6s ease;
    }

    .product_tab_item:hover  {
        background-size: 110% 110%;
        cursor: pointer;
    }

    .product_tab_item_title {
        font-size: 28px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 2px;
        color: #ffffff;
        text-align: center;
        white-space: pre-wrap;
    }

    .product_tab_item_btn {
        width: 50%;
        border: 2px solid white;
        padding: 5px;
        color: white;
        text-align: center;
    }

</style>