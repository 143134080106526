<template>
    <div class="container">
        <el-image
            style="width: 100%;"
            :src="imageUrl + 'product/product_item_banner.jpg'"
            fit="contain">
        </el-image>
        <span style="font-size: 24px;margin: 20px">{{transText('搜索结果')}}</span>
        <el-input
            class="search_input"
            clearable
            v-model="searchKeyword"
            :placeholder="transText('请输入搜索关键字')"
            :maxlength="10"
            input-style="border-radius: 100px;padding-left:20px"
            @change="onSearch"
        >
            <template #prefix>
                <el-icon class="el-input__icon"><search /></el-icon>
            </template>
        </el-input>
        <div class="search_category">
            <span v-for="(item,index) in searchTypeData" :key="index"
                  :class="selectIndex === index ? 'search_selected' : 'search_unselected'"
                  @click="onCategoryClicked(index)">{{ item.title }} ({{item.count}})</span>
        </div>
        <div class="search_result" v-if="searchResultData">
            <SearchResultItem v-for="(item, index) in searchResultData[selectIndex]" :key="index"
                                  :keyword="searchKeyword" :product-item="item"
                              @click="onProductItemClicked(item)"
            />
        </div>

    </div>
</template>

<script>
import { Search } from '@element-plus/icons-vue';
import {ImageOSSUrl, getBasicTextByLang} from "@/js/netApi";
import SearchResultItem from "@/components/SearchResultItem.vue";

export default {
    name: "SearchResultComponent",
    components: {
        SearchResultItem,
        Search
    },
    props: {
        'productData': {},
        'keyword': {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            searchKeyword: '',
            selectIndex: '',
            imageUrl: ImageOSSUrl,
            productAllData: {},
            searchResultData: {},
            searchTypeData: {}
        }
    },
    mounted() {
        this.searchKeyword = this.keyword ? this.keyword : this.$route.query.keyword

        this.productAllData = this.productData.product_data?.data
        this.onSearch()
    },
    methods: {
        onProductItemClicked(item) {
            let urlObj = this.$router.resolve({path: '/productItem'})

            localStorage.setItem("currentProduct", JSON.stringify(item))

            window.open(urlObj.href, "_blank")

        },
        onSearch() {
            this.searchResultData = {}
            this.searchTypeData = {}
            this.selectIndex = ''

            if ((!this.searchKeyword) || (!this.productAllData)) {
                return
            }

            this.searchTypeData = {
                "all": {
                    "title": this.transText('全部'),
                    "count": 0
                }
            }

            this.selectIndex = 'all'
            this.searchResultData['all'] = []

            for (let i = 0 ; i < this.productAllData.length ; i++) {
                let productItem = this.productAllData[i]
                if (!productItem) {
                   continue
                }

                if ((productItem.type.value.indexOf(this.searchKeyword) >= 0) || (productItem.mode.value.indexOf(this.searchKeyword) >= 0) ||
                    (productItem.material.value.indexOf(this.searchKeyword) >= 0) || (productItem.body_color.value.indexOf(this.searchKeyword) >= 0) ||
                    (productItem.keywords.value.indexOf(this.searchKeyword) >= 0)) {

                    this.searchResultData['all'].push(productItem)
                    this.searchTypeData['all'].count++

                    if (productItem.type.value in this.searchTypeData) {
                        this.searchTypeData[productItem.type.value].count++
                        this.searchResultData[productItem.type.value].push(productItem)
                    } else {
                        this.searchTypeData[productItem.type.value] = {
                            "title": productItem.type.value,
                            "count": 1
                        }

                        this.searchResultData[productItem.type.value] = [productItem]
                    }
                }
            }
        },

        onCategoryClicked(idx) {
            this.selectIndex = idx
        },

        transText(text) {
            return getBasicTextByLang(text)
        },
    }
}
</script>

<style scoped>
    .container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
    }

    .search_input {
        width: 50%;
        border: solid 1px #ccc ;
        margin-bottom: 40px;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .product-item-view {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 75%;
        margin-bottom: 80px;
    }

    .el-input__wrapper {
        border-radius: 100px !important;

    }

    .search_category {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 50%;
        margin-bottom: 80px;
        flex-wrap: wrap;
    }

    .search_result {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 50%;
        margin-bottom: 80px;
    }

    .search_selected {
        padding: 10px 20px 10px 20px;
        background-color: #CFA88C30;
        border-radius: 100px;
        color: #CFA88C;
    }

    .search_unselected {
        color: #888;
        cursor: pointer;
        padding: 10px 20px 10px 20px
    }
</style>