import LocalStorage from "@/js/localStorage";

export function getAgentCountry() {
    return new Promise(function (resolve, reject) {
        let xhr = new XMLHttpRequest();
        xhr.open('GET', 'https://token.ip.api.useragentinfo.com/json?token=ab28a017dc0b7536f452fd951aed51d2', false);
        xhr.responseType = 'json';
        xhr.send();
        xhr.onload = function () {
            if (xhr.status === 200) {
                resolve(xhr.response);
            } else {
                reject('Error retrieving data from server');
            }
        };
        xhr.onerror = function () {
            reject('Error retrieving data from server');
        }
    });
}

export async function checkImageExist(url) {

    try {
        const response = await fetch(url, {
            method: 'HEAD'
        });

        return response.ok;

    } catch (error) {
        console.error('网络请求出错：', error);
        return false;
    }


}

export function getImageUrlByLang(url, cnt, type) {
    let lang = LocalStorage.getStorage('lang')

    return url + cnt + '-' + lang + '.' + type
}

export function getBasicTextByLang(text) {
    if ((!text) || (text === '')) {
        return text
    }

    let lang = LocalStorage.getStorage('lang')
    if (lang === 'zh') {
        return text
    }

    for (let i = 0 ; i < BASIC_TEXT_ARR.length ; i++) {
        let textObj = BASIC_TEXT_ARR[i]
        if (!textObj) {
            continue
        }

        if (textObj.zh === text) {
            if (lang === 'en') {
                return textObj.en
            } else if (lang === 'cht') {
                return textObj.cht
            } else {
                return text
            }
        }
    }

    return text
}

const BASE_URL = "https://www.geniuslighting.com"

export const WEB_URL = BASE_URL + '/index.html'
export const MOBILE_URL = BASE_URL + '/m/index.html'

export function isMobile() {
    let userAgent = navigator.userAgent;
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)
}

const BASIC_TEXT_ARR = [
    { "zh": '关 闭', "en": "Close", "cht": "關 閉" },
    { "zh": '全部', "en": "All", "cht": "全部" },
    { "zh": '产品中心', "en": "PRODUCT CENTER", "cht": "產品中心" },
    { "zh": '产品参数', "en": "Specification List", "cht": "產品參數" },
    { "zh": '产品详情', "en": "Details", "cht": "產品詳情" },
    { "zh": '推荐新闻', "en": "NEWS", "cht": "推薦新聞" },
    { "zh": '热门关键词', "en": "KEYWORDS", "cht": "熱門關鍵字" },
    { "zh": '搜索结果', "en": "Search Results", "cht": "搜索結果" },
    { "zh": '请输入搜索关键字', "en": "Please enter search keywords", "cht": "請輸入蒐索關鍵字" },
    { "zh": '查看更多', "en": "See more", "cht": "查看更多" },
    { "zh": '室内空间照明', "en": "Interior Space Lighting", "cht": "室內空間照明" },
    { "zh": '装饰艺术照明', "en": "Decoration Art Lighting", "cht": "裝飾藝術照明" },
    { "zh": '户外庭院照明', "en": "Outdoor Courtyard Lighting", "cht": "戶外庭院照明" },
    { "zh": '智能控制系统', "en": "Intelligent Control System", "cht": "智慧控制系統" }
]

export const ImageOSSUrl = BASE_URL + '/image/' ;
export const DataOSSURL = BASE_URL + "/data/"

